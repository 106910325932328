.Category-modal {
}

.Scrollable {
  max-height: 450px;
  overflow: scroll;
}

.Category-modal-category {
  background-color: rgb(255, 145, 0);
}

.Category-modal-delete {
  cursor: pointer;
  color: #666;
  margin-right: 10px;
}

.Delete-dialog h2 {
  color: #555;
}
