.Home {
}
html,
body {
  overflow-x: hidden;
  position: relative;
}
h1 {
  color: #054188;
  font-size: 50px;
  font-weight: 600;
  line-height: 50px;
  letter-spacing: -1px;
  margin-bottom: -10px;
}

.Test {
  /* z-index: -1;
  position: absolute;
  height: 100vh; */
}

.Home-logo {
  margin-left: 60px;
  margin-top: 30px;
  -webkit-animation: swell2 15s ease -1.25s infinite;
  -moz-animation: swell2 15s ease -1.25s infinite;
  -o-animation: swell2 15s ease -1.25s infinite;
  animation: swell2 15s ease -1.25s infinite;
}

@media (max-width: 600px) {
  .Home-logo {
    /* width: 150px; */
    margin-top: 30px;
    margin-left: 0;
    margin-right: 0;
  }
}

.Home-top-wave {
  position: absolute;
  z-index: -1;
  top: 0;
}

@media (max-width: 1600px) {
  .Home-top-wave {
    visibility: hidden;
    position: absolute;
    z-index: 0;
    opacity: 0.1;
  }
}
.Home-top-wave-small {
  visibility: hidden;
  position: absolute;
  top: 0;
  z-index: -1;
  opacity: 0.5;
}

@media (max-width: 1600px) {
  .Home-top-wave-small {
    visibility: visible;
    /* opacity: 0.1; */
  }
}

.Home-header {
  background: linear-gradient(
      rgba(255, 255, 255, 0) 60%,
      rgba(255, 255, 255, 1)
    ),
    radial-gradient(ellipse at 50% 20%, #fff 15%, #dfeeff 100%);
}

@media (max-width: 800px) {
  .Home-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(
        rgba(255, 255, 255, 0) 60%,
        rgba(255, 255, 255, 1)
      ),
      radial-gradient(circle at 50% 20%, #fff 15%, #dfeeff 100%);
  }
}

.Home-header-container {
  max-width: 1400px;
  margin: auto;
  /* justify-content: center;
    align-items: center; */
}

.Home-logo-container {
}

@media (max-width: 600px) {
  .Home-logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.Home-header-right {
  z-index: 5;
  display: flex;
  justify-content: flex-start;
}

.Home-header-left {
  display: flex;
  /* justify-content: flex-end; */
  /* align-items: center; */
  padding-top: 6rem;
}

@media (max-width: 1280px) {
  .Home-header-left {
    padding-top: 0;
  }
}

@media (min-width: 1920px) {
  .Home-header-left {
    /* padding-top: 15rem; */
  }
}

.Home-lines {
  position: absolute;
  width: 90%;
  height: 10px;
  margin-top: 20px;
  margin-left: 60px;
  z-index: -5;
  background-color: black;
}

.Home-header-left-content {
  /* margin-right: 10%; */
  margin-left: 10%;
  width: 85%;
}

@media (max-width: 1280px) {
  .Home-header-left-content {
    width: 85%;
  }
}

@media (min-width: 1920px) {
  .Home-header-left-content {
    /* width: 50%; */
    margin-right: 5%;
    /* width: 60%; */
  }
}

.Home-header-left-content-body {
  font-size: 18px;
  line-height: 24px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.Home-header-screenshot-container {
  margin-right: 2%;
}

@media (max-width: 600px) {
  .Home-header-screenshot-container {
    margin-left: 2%;
  }
}

.Home-header-screenshot {
  /* justify-content: flex-start; */

  max-width: 100%;
  /* width: 100%; */
  margin-top: -60px;
}

@media (max-width: 960px) {
  .Home-header-screenshot {
    /* min-width: 96%; */
    max-height: 36vw;
    /* margin-left: 2%;
    margin-right: 2%; */
    margin-top: 30px;
  }
}

@media (max-width: 600px) {
  .Home-header-screenshot {
    /* min-width: 96%; */
    /* width: 96%; */
    max-height: 100%;
    /* margin-left: 1%;
    margin-right: 1%; */
    margin-top: 30px;
  }
}

/* @media (max-width: 800px) {
  .Home-header-screenshot {
    width: 96%;
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 30px;
  }
} */

.Svg-element {
  display: block;
  margin-bottom: -1px;
}

.Home-animation-container {
  position: absolute;
  z-index: 1;
  max-width: 1400px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  /* margin-left: 10%; */
  margin: auto;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 800px) {
  .Home-animation-container {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 0px;
  }
}

.Home-animated-logo {
  /* position: absolute; */
  margin-top: -2rem;
  width: 100px;
  animation: float 6s infinite;
  opacity: 0;
}

@media (max-width: 800px) {
  .Home-animated-logo {
    width: 15%;
    margin-top: 1rem;
  }
}

@keyframes float {
  0% {
    transform: translatey(0px);
    opacity: 0.03;
  }
  50% {
    transform: translatey(-60px);
    opacity: 0.03;
  }
  100% {
    transform: translatey(0px);
    opacity: 0.03;
  }
}

.Home-use-cases {
  /* background: #fff; */
  text-align: center;
  width: 80%;
  max-width: 1400px;
  margin: auto;
  margin-top: 50px;
  min-height: 500px;
}

@media (max-width: 960px) {
  .Home-use-cases {
    width: 100%;
  }
}

.Home-use-cases-tabs {
  box-shadow: inset 0 -2px 0 #aaa;
  margin-top: 20px;
}

.Home-use-cases-tab-content-left {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 30px;
}
@media (max-width: 600px) {
  .Home-use-cases-tab-content-left {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.Home-use-cases-tab-content-right {
  text-align: left;
  display: flex;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 50px;
  padding-bottom: 50px;
  font-size: 20px;
}
@media (max-width: 600px) {
  .Home-use-cases-tab-content-right {
    padding-top: 20px;
    padding-bottom: 10px;
    padding-left: 0;
    padding-right: 0;
    font-size: 18px;
  }
}

.Home-bottom-container {
  background: linear-gradient(#dfeeff, #fff);
  width: 100%;
}

.Home-how {
  width: 80%;
  max-width: 1400px;
  margin: auto;
  text-align: center;
}

@media (max-width: 960px) {
  .Home-how {
    width: 90%;
  }
}

@media (max-width: 600px) {
  .Home-how {
    padding-top: 30px;
  }
}

.Home-section-title {
  font-family: "Poppins", sans-serif;
  color: #054188;
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 50px;
}

.Home-section-title-light {
  font-family: "Poppins", sans-serif;
  color: #fff;
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 50px;
}

.Home-how-section {
  margin-top: 30px;
  margin-bottom: 10px;
}

.Home-how-section-steps {
  font-size: 18px;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 20px;
}

.Home-how-section-numbers {
  font-family: "Work Sans", sans-serif;
  font-size: 100px;
  font-weight: 200;
  color: #054188;
  line-height: 100px;
}

.Home-features {
  background-color: #054188;
  /* margin-top: 50px; */
  /* margin-bottom: 30px; */
  /* padding-bottom: 100px; */
}

.Home-features-section-pieces {
  font-size: 18px;
  padding-left: 5%;
  padding-right: 5%;
  /* padding-bottom: 30px; */
}

@media (max-width: 800px) {
  .Home-features-section-pieces {
    padding-top: 40px;
  }
}

.Home-features-section-pieces h2 {
  color: #fff;
  font-weight: 600;
  font-size: 18px;
}

.Home-features-section-pieces p {
  color: #dfeeff;
}

.Home-timer-numbers {
  font-size: 60px;
  font-weight: 500;
  font-family: "Oxanium";
  color: #f55472;
}

.Home-features-board-title {
  font-size: 42px;
  font-weight: 500;
  font-family: "Poppins";
  color: #577ea8;
  margin-left: -4px;
}

.Home-features-image {
  height: 160px;
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 960px) {
  .Home-features-image {
    height: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.blinking-cursor {
  font-weight: 100;
  font-size: 50px;
  color: #7a8da1;
  -webkit-animation: 1s blink step-end infinite;
  -moz-animation: 1s blink step-end infinite;
  -ms-animation: 1s blink step-end infinite;
  -o-animation: 1s blink step-end infinite;
  animation: 1s blink step-end infinite;
}

@keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #7a8da1;
  }
}

@-moz-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #7a8da1;
  }
}

@-webkit-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #7a8da1;
  }
}

@-ms-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #7a8da1;
  }
}

@-o-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #7a8da1;
  }
}

.Footer {
  min-height: 200px;
  width: 100%;
  background-color: #022855;
}

.Footer h2 {
  color: #dfeeff;
}

.Footer p {
  color: #dfeeff;
}

.Footer-content {
  width: 80%;
  max-width: 1400px;
  margin: auto;
  color: #fff;
  justify-content: space-around;
  padding-top: 30px;
  padding-bottom: 10px;
}

@media (max-width: 960px) {
  .Footer-content {
    width: 90%;
  }
}

.Footer-content-sections {
  padding-bottom: 20px;
  padding-left: 5%;
  padding-right: 5%;
  /* padding-bottom: 20px; */
}
.Footer-content-header {
  font-size: 24px;
  font-weight: 700;
}

.Wave-container {
  /* background: linear-gradient(#dfeeff, transparent); */
  height: 5%;
  width: 100%;
  /* overflow: hidden; */
  position: absolute;
  top: 0;
  left: 0;
  /* z-index: -1; */
  /* transform: rotate(180deg); */
  /* background: #015871; */
}

.Wave {
  background: url("../assets/topWave.svg") repeat-x;
  position: absolute;
  /* top: -198px; */
  top: 23;
  width: 6400px;
  height: 198px;
  -webkit-animation: wave 15s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  -moz-animation: wave 15s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  -o-animation: wave 15s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  animation: wave 15s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
}

.Wave:nth-of-type(2) {
  /* top: -175px; */
  -webkit-animation: wave 15s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
    swell 10s ease -1.25s infinite;
  -moz-animation: wave 15s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
    swell 10s ease -1.25s infinite;
  -o-animation: wave 15s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
    swell 10s ease -1.25s infinite;
  animation: wave 15s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
    swell 10s ease -1.25s infinite;
  opacity: 1;
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}

@keyframes swell {
  0%,
  100% {
    transform: translate3d(0, -25px, 0);
  }
  50% {
    transform: translate3d(0, 5px, 0);
  }
}

@keyframes swell2 {
  0%,
  100% {
    transform: translate3d(0, -15px, 0);
  }
  50% {
    transform: translate3d(0, 5px, 0);
  }
}
