.Top-container {
  height: 100vh;
  background: radial-gradient(
    ellipse at center,
    rgb(255, 252, 247) 0%,
    rgb(255, 252, 245) 35%,
    #dfeeff 100%
  );
  /* overflow: hidden; */
}

.Four-logo {
  margin-left: 60px;
  margin-top: 30px;
}

@media (max-width: 800px) {
  .Four-logo {
    display: flex;
    justify-content: center;
    margin: auto;
    padding-top: 30px;
  }
}

.FourText {
  text-align: center;
}

.FourText h1 {
  color: #d6425d;
  margin-top: 60px;
  margin-bottom: 10px;
}

.FourText p {
  color: #525e6b;
  font-size: 18px;
}

.Wave-bottom-container {
  /* position: absolute; */
  display: flex;
  flex-direction: column;
  /* justify-content: flex-end; */
  bottom: 0;
  height: 100%;
  /* height: 30%; */
  /* display: flex; */
  /* align-items: flex-end; */
}

.FourO {
  /* position: relative; */
  display: flex;
  width: 100vw;
  justify-content: center;
  padding-top: 50px;
  margin-bottom: -150px;
  font-size: 170px;
  font-family: "Poppins", sans-serif;
  color: #d6425d;
  font-weight: 700;
  -webkit-animation: swellRock 10s ease-out -2.25s infinite;
  -moz-animation: swellRock 10s ease-out -2.25s infinite;
  -o-animation: swellRock 10s ease-out -2.25s infinite;
  animation: swellRock 10s ease-out -2.25s infinite;
  transform: translate3d(0, 0px, 0);
}

@media (max-width: 1600px) {
  .FourO {
    -webkit-animation: swellSmall 10s ease-out -2.25s infinite;
    -moz-animation: swellSmall 10s ease-out -2.25s infinite;
    -o-animation: swellSmall 10s ease-out -2.25s infinite;
    animation: swellSmall 10s ease-out -2.25s infinite;
    transform: translate3d(0, 0, 0);
  }
}

@media (max-width: 1000px) {
  .FourO {
    -webkit-animation: swellXS 10s ease-out -2.25s infinite;
    -moz-animation: swellXS 10s ease-out -2.25s infinite;
    -o-animation: swellXS 10s ease-out -2.25s infinite;
    animation: swellXS 10s ease-out -2.25s infinite;
    transform: translate3d(0, 30px, 0);
  }
}

@media (max-width: 450px) {
  .FourO {
    -webkit-animation: swellXXS 10s ease-out -2.25s infinite;
    -moz-animation: swellXXS 10s ease-out -2.25s infinite;
    -o-animation: swellXXS 10s ease-out -2.25s infinite;
    animation: swellXXS 10s ease-out -2.25s infinite;
    transform: translate3d(0, 0, 0);
  }
}

@media (min-width: 2000px) {
  .FourO {
    -webkit-animation: swellLarge 10s ease-out -2.25s infinite;
    -moz-animation: swellLarge 10s ease-out -2.25s infinite;
    -o-animation: swellLarge 10s ease-out -2.25s infinite;
    animation: swellLarge 10s ease-out -2.25s infinite;
    transform: translate3d(0, -30px, 0);
  }
}

@media (min-width: 2800px) {
  .FourO {
    -webkit-animation: swellXL 10s ease-out -2.25s infinite;
    -moz-animation: swellXL 10s ease-out -2.25s infinite;
    -o-animation: swellXL 10s ease-out -2.25s infinite;
    animation: swellXL 10s ease-out -2.25s infinite;
    transform: translate3d(0, 0, 0);
  }
}

.FourORotate {
  -webkit-animation: rock 10s linear infinite;
  -moz-animation: rock 10s linear infinite;
  -o-animation: rock 10s linear infinite;
  animation: rock 10s linear infinite;
  transform: rotate(0);
}

@media (max-width: 1000px) {
  .FourORotate {
    -webkit-animation: rockXS 10s ease-out infinite;
    -moz-animation: rockXS 10s ease-out infinite;
    -o-animation: rockXS 10s ease-out infinite;
    animation: rockXS 10s ease-out infinite;
    transform: rotate(-6deg);
  }
}

@media (max-width: 450px) {
  .FourORotate {
    -webkit-animation: rockXXS 10s ease-out infinite;
    -moz-animation: rockXXS 10s ease-out infinite;
    -o-animation: rockXXS 10s ease-out infinite;
    animation: rockXXS 10s ease-out infinite;
    transform: rotate(0deg);
  }
}

@media (min-width: 2000px) {
  .FourORotate {
    -webkit-animation: rockLarge 10s ease-out infinite;
    -moz-animation: rockLarge 10s ease-out infinite;
    -o-animation: rockLarge 10s ease-out infinite;
    animation: rockLarge 10s ease-out infinite;
    transform: rotate(6deg);
  }
}

@media (min-width: 2800px) {
  .FourORotate {
    -webkit-animation: rockXL 10s linear infinite;
    -moz-animation: rockXL 10s linear infinite;
    -o-animation: rockXL 10s linear infinite;
    animation: rockXL 10s linear infinite;
    transform: rotate(0deg);
  }
}

.Wave-bottom {
  background: url("../assets/bottomWave.svg") repeat-x;
  position: absolute;
  /* top: -198px; */
  margin-top: 50px;
  width: 6400px;
  height: 198px;
  -webkit-animation: wave 10s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  -moz-animation: wave 10s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  -o-animation: wave 10s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  animation: wave 10s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
}

.Wave-bottom:nth-of-type(2) {
  /* top: -175px; */
  -webkit-animation: wave 10s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
    swell 15s ease -1.25s infinite;
  -moz-animation: wave 10s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
    swell 15s ease -1.25s infinite;
  -o-animation: wave 10s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
    swell 15s ease -1.25s infinite;
  animation: wave 10s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
    swell 15s ease -1.25s infinite;
  opacity: 1;
}

.Deep-sea {
  z-index: 1;
  position: relative;
  /* bottom: 0; */
  margin-top: 220px;
  height: calc(100vh - 550px);
  /* height: 100px; */
  width: 100%;
  background: linear-gradient(#054188, #011a38);
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}

@keyframes swell {
  0%,
  100% {
    transform: translate3d(0, -30px, 0);
  }
  50% {
    transform: translate3d(0, 5px, 0);
  }
}

@keyframes swellXXS {
  25% {
    transform: translate3d(0, 30px, 0);
    animation-timing-function: ease-in;
  }
  50% {
    transform: translate3d(0, 0, 0);
    animation-timing-function: ease-out;
  }
  75% {
    transform: translate3d(0, -30px, 0);
    animation-timing-function: ease-in;
  }
  100% {
    transform: translate3d(0, 0, 0);
    animation-timing-function: ease-out;
  }
}

@keyframes swellXS {
  0% {
    transform: translate3d(0, 30px, 0);
    animation-timing-function: ease-in;
  }
  25% {
    transform: translate3d(0, 0, 0);
    animation-timing-function: ease-out;
  }
  50% {
    transform: translate3d(0, -30px, 0);
    animation-timing-function: ease-in;
  }
  75% {
    transform: translate3d(0, 0, 0);
    animation-timing-function: ease-out;
  }
}

@keyframes swellSmall {
  25% {
    transform: translate3d(0, -30px, 0);
    animation-timing-function: ease-in;
  }
  50% {
    transform: translate3d(0, 0, 0);
    animation-timing-function: ease-out;
  }
  75% {
    transform: translate3d(0, 30px, 0);
    animation-timing-function: ease-in;
  }
  100% {
    transform: translate3d(0, 0, 0);
    animation-timing-function: ease-out;
  }
}

@keyframes swellRock {
  25% {
    transform: translate3d(0, -30px, 0);
    animation-timing-function: ease-in;
  }
  50% {
    transform: translate3d(0, 0, 0);
    animation-timing-function: ease-out;
  }
  75% {
    transform: translate3d(0, 30px, 0);
    animation-timing-function: ease-in;
  }
  100% {
    transform: translate3d(0, 0, 0);
    animation-timing-function: ease-out;
  }
}

@keyframes swellLarge {
  0% {
    transform: translate3d(0, -30px, 0);
    animation-timing-function: ease-in;
  }
  25% {
    transform: translate3d(0, 0, 0);
    animation-timing-function: ease-out;
  }
  50% {
    transform: translate3d(0, 30px, 0);
    animation-timing-function: ease-in;
  }
  75% {
    transform: translate3d(0, 0, 0);
    animation-timing-function: ease-out;
  }
}

@keyframes swellXL {
  25% {
    transform: translate3d(0, 30px, 0);
    animation-timing-function: ease-in;
  }
  50% {
    transform: translate3d(0, 0, 0);
    animation-timing-function: ease-out;
  }
  75% {
    transform: translate3d(0, -30px, 0);
    animation-timing-function: ease-in;
  }
  100% {
    transform: translate3d(0, 0, 0);
    animation-timing-function: ease-out;
  }
}

@keyframes rock {
  25% {
    transform: rotate(6deg);
    animation-timing-function: ease-in;
  }
  50% {
    transform: rotate(0deg);
    animation-timing-function: ease-out;
  }
  75% {
    transform: rotate(-6deg);
    animation-timing-function: ease-in;
  }
  100% {
    transform: rotate(0deg);
    animation-timing-function: linear;
  }
}

@keyframes rockXS {
  0% {
    transform: rotate(-6deg);
    animation-timing-function: ease-in;
  }
  25% {
    transform: rotate(0deg);
    animation-timing-function: ease-out;
  }
  50% {
    transform: rotate(6deg);
    animation-timing-function: ease-in;
  }
  75% {
    transform: rotate(0deg);
    animation-timing-function: linear;
  }
}

@keyframes rockXXS {
  25% {
    transform: rotate(-6deg);
    animation-timing-function: ease-in;
  }
  50% {
    transform: rotate(0deg);
    animation-timing-function: ease-out;
  }
  75% {
    transform: rotate(6deg);
    animation-timing-function: ease-in;
  }
  100% {
    transform: rotate(0deg);
    animation-timing-function: linear;
  }
}

@keyframes rockLarge {
  0% {
    transform: rotate(6deg);
    animation-timing-function: ease-in;
  }
  25% {
    transform: rotate(0deg);
    animation-timing-function: ease-out;
  }
  50% {
    transform: rotate(-6deg);
    animation-timing-function: ease-in;
  }
  75% {
    transform: rotate(0deg);
    animation-timing-function: linear;
  }
}

@keyframes rockXL {
  25% {
    transform: rotate(-6deg);
    animation-timing-function: ease-in;
  }
  50% {
    transform: rotate(0deg);
    animation-timing-function: ease-out;
  }
  75% {
    transform: rotate(6deg);
    animation-timing-function: ease-in;
  }
  100% {
    transform: rotate(0deg);
    animation-timing-function: linear;
  }
}
