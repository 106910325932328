.App {
  /* text-align: center; */
  justify-content: center;

}

.App-logo {
  margin-left: 30px;
  margin-top: 20px;
}

.App-header {
  background-color: #fff;
  min-height: 100vh;
  width: 740px;
  margin: auto;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #444;
}


.App-title {
  font-size: calc(10px + 5vmin);
  border: 0;
  outline: none;
  color: #444;
  font-weight: 700;
  padding-bottom: 20px;
}::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ccc;
  opacity: 1; /* Firefox */
}

.App-prompt {
  font-size: calc(8px + 2vmin);
  border: 0;
  outline: none;
  color: #444;
  padding-bottom: 50px;
}::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aaa;
  opacity: 1; /* Firefox */
}

.App-instructions {
  margin-bottom: 50px;
  /* margin-left: 60px; */
  /* margin-right: 100px; */
  background-color: #E2EFFF;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-style: none none none solid;
  border-width: 6px;
  border-color: #4083D2;
}p {
  margin-bottom: 8px;
  margin-top: 8px;
}h2 {
  margin-bottom: 0px;
  margin-top: 6px;
}

.App-responses {
  font-size: calc(10px + 1vmin);
  border: 0;
  outline: none;
  color: #222;
  padding-bottom: 50px;
}

.App-response-date {
  font-size: calc(8px + 1vmin);
  outline: none;
  color: #666;
  margin-top: 2px;
}

h2 {
  color: #555;
  font-size: 24px;
  font-weight: 500;
}

.App-link {
  color: #61dafb;
}

/* p {
  font-size: calc(10px + 1vmin);
} */

a {
  color: #4083D2;
  text-decoration: none;
}
a:hover {
   color: #2469BB;
}

