@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@200;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oxanium:wght@400&display=swap);
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Work Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2 {
  font-family: "Poppins", sans-serif;
}

.App {
  /* text-align: center; */
  justify-content: center;

}

.App-logo {
  margin-left: 30px;
  margin-top: 20px;
}

.App-header {
  background-color: #fff;
  min-height: 100vh;
  width: 740px;
  margin: auto;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #444;
}


.App-title {
  font-size: calc(10px + 5vmin);
  border: 0;
  outline: none;
  color: #444;
  font-weight: 700;
  padding-bottom: 20px;
}::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ccc;
  opacity: 1; /* Firefox */
}:-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ccc;
  opacity: 1; /* Firefox */
}::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ccc;
  opacity: 1; /* Firefox */
}

.App-prompt {
  font-size: calc(8px + 2vmin);
  border: 0;
  outline: none;
  color: #444;
  padding-bottom: 50px;
}::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aaa;
  opacity: 1; /* Firefox */
}:-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aaa;
  opacity: 1; /* Firefox */
}::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aaa;
  opacity: 1; /* Firefox */
}

.App-instructions {
  margin-bottom: 50px;
  /* margin-left: 60px; */
  /* margin-right: 100px; */
  background-color: #E2EFFF;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-style: none none none solid;
  border-width: 6px;
  border-color: #4083D2;
}p {
  margin-bottom: 8px;
  margin-top: 8px;
}h2 {
  margin-bottom: 0px;
  margin-top: 6px;
}

.App-responses {
  font-size: calc(10px + 1vmin);
  border: 0;
  outline: none;
  color: #222;
  padding-bottom: 50px;
}

.App-response-date {
  font-size: calc(8px + 1vmin);
  outline: none;
  color: #666;
  margin-top: 2px;
}

h2 {
  color: #555;
  font-size: 24px;
  font-weight: 500;
}

.App-link {
  color: #61dafb;
}

/* p {
  font-size: calc(10px + 1vmin);
} */

a {
  color: #4083D2;
  text-decoration: none;
}
a:hover {
   color: #2469BB;
}


.Home {
}
html,
body {
  overflow-x: hidden;
  position: relative;
}
h1 {
  color: #054188;
  font-size: 50px;
  font-weight: 600;
  line-height: 50px;
  letter-spacing: -1px;
  margin-bottom: -10px;
}

.Test {
  /* z-index: -1;
  position: absolute;
  height: 100vh; */
}

.Home-logo {
  margin-left: 60px;
  margin-top: 30px;
  animation: swell2 15s ease -1.25s infinite;
}

@media (max-width: 600px) {
  .Home-logo {
    /* width: 150px; */
    margin-top: 30px;
    margin-left: 0;
    margin-right: 0;
  }
}

.Home-top-wave {
  position: absolute;
  z-index: -1;
  top: 0;
}

@media (max-width: 1600px) {
  .Home-top-wave {
    visibility: hidden;
    position: absolute;
    z-index: 0;
    opacity: 0.1;
  }
}
.Home-top-wave-small {
  visibility: hidden;
  position: absolute;
  top: 0;
  z-index: -1;
  opacity: 0.5;
}

@media (max-width: 1600px) {
  .Home-top-wave-small {
    visibility: visible;
    /* opacity: 0.1; */
  }
}

.Home-header {
  background: linear-gradient(
      rgba(255, 255, 255, 0) 60%,
      rgba(255, 255, 255, 1)
    ),
    radial-gradient(ellipse at 50% 20%, #fff 15%, #dfeeff 100%);
}

@media (max-width: 800px) {
  .Home-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(
        rgba(255, 255, 255, 0) 60%,
        rgba(255, 255, 255, 1)
      ),
      radial-gradient(circle at 50% 20%, #fff 15%, #dfeeff 100%);
  }
}

.Home-header-container {
  max-width: 1400px;
  margin: auto;
  /* justify-content: center;
    align-items: center; */
}

.Home-logo-container {
}

@media (max-width: 600px) {
  .Home-logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.Home-header-right {
  z-index: 5;
  display: flex;
  justify-content: flex-start;
}

.Home-header-left {
  display: flex;
  /* justify-content: flex-end; */
  /* align-items: center; */
  padding-top: 6rem;
}

@media (max-width: 1280px) {
  .Home-header-left {
    padding-top: 0;
  }
}

@media (min-width: 1920px) {
  .Home-header-left {
    /* padding-top: 15rem; */
  }
}

.Home-lines {
  position: absolute;
  width: 90%;
  height: 10px;
  margin-top: 20px;
  margin-left: 60px;
  z-index: -5;
  background-color: black;
}

.Home-header-left-content {
  /* margin-right: 10%; */
  margin-left: 10%;
  width: 85%;
}

@media (max-width: 1280px) {
  .Home-header-left-content {
    width: 85%;
  }
}

@media (min-width: 1920px) {
  .Home-header-left-content {
    /* width: 50%; */
    margin-right: 5%;
    /* width: 60%; */
  }
}

.Home-header-left-content-body {
  font-size: 18px;
  line-height: 24px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.Home-header-screenshot-container {
  margin-right: 2%;
}

@media (max-width: 600px) {
  .Home-header-screenshot-container {
    margin-left: 2%;
  }
}

.Home-header-screenshot {
  /* justify-content: flex-start; */

  max-width: 100%;
  /* width: 100%; */
  margin-top: -60px;
}

@media (max-width: 960px) {
  .Home-header-screenshot {
    /* min-width: 96%; */
    max-height: 36vw;
    /* margin-left: 2%;
    margin-right: 2%; */
    margin-top: 30px;
  }
}

@media (max-width: 600px) {
  .Home-header-screenshot {
    /* min-width: 96%; */
    /* width: 96%; */
    max-height: 100%;
    /* margin-left: 1%;
    margin-right: 1%; */
    margin-top: 30px;
  }
}

/* @media (max-width: 800px) {
  .Home-header-screenshot {
    width: 96%;
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 30px;
  }
} */

.Svg-element {
  display: block;
  margin-bottom: -1px;
}

.Home-animation-container {
  position: absolute;
  z-index: 1;
  max-width: 1400px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  /* margin-left: 10%; */
  margin: auto;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 800px) {
  .Home-animation-container {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 0px;
  }
}

.Home-animated-logo {
  /* position: absolute; */
  margin-top: -2rem;
  width: 100px;
  animation: float 6s infinite;
  opacity: 0;
}

@media (max-width: 800px) {
  .Home-animated-logo {
    width: 15%;
    margin-top: 1rem;
  }
}

@keyframes float {
  0% {
    transform: translatey(0px);
    opacity: 0.03;
  }
  50% {
    transform: translatey(-60px);
    opacity: 0.03;
  }
  100% {
    transform: translatey(0px);
    opacity: 0.03;
  }
}

.Home-use-cases {
  /* background: #fff; */
  text-align: center;
  width: 80%;
  max-width: 1400px;
  margin: auto;
  margin-top: 50px;
  min-height: 500px;
}

@media (max-width: 960px) {
  .Home-use-cases {
    width: 100%;
  }
}

.Home-use-cases-tabs {
  box-shadow: inset 0 -2px 0 #aaa;
  margin-top: 20px;
}

.Home-use-cases-tab-content-left {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 30px;
}
@media (max-width: 600px) {
  .Home-use-cases-tab-content-left {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.Home-use-cases-tab-content-right {
  text-align: left;
  display: flex;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 50px;
  padding-bottom: 50px;
  font-size: 20px;
}
@media (max-width: 600px) {
  .Home-use-cases-tab-content-right {
    padding-top: 20px;
    padding-bottom: 10px;
    padding-left: 0;
    padding-right: 0;
    font-size: 18px;
  }
}

.Home-bottom-container {
  background: linear-gradient(#dfeeff, #fff);
  width: 100%;
}

.Home-how {
  width: 80%;
  max-width: 1400px;
  margin: auto;
  text-align: center;
}

@media (max-width: 960px) {
  .Home-how {
    width: 90%;
  }
}

@media (max-width: 600px) {
  .Home-how {
    padding-top: 30px;
  }
}

.Home-section-title {
  font-family: "Poppins", sans-serif;
  color: #054188;
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 50px;
}

.Home-section-title-light {
  font-family: "Poppins", sans-serif;
  color: #fff;
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 50px;
}

.Home-how-section {
  margin-top: 30px;
  margin-bottom: 10px;
}

.Home-how-section-steps {
  font-size: 18px;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 20px;
}

.Home-how-section-numbers {
  font-family: "Work Sans", sans-serif;
  font-size: 100px;
  font-weight: 200;
  color: #054188;
  line-height: 100px;
}

.Home-features {
  background-color: #054188;
  /* margin-top: 50px; */
  /* margin-bottom: 30px; */
  /* padding-bottom: 100px; */
}

.Home-features-section-pieces {
  font-size: 18px;
  padding-left: 5%;
  padding-right: 5%;
  /* padding-bottom: 30px; */
}

@media (max-width: 800px) {
  .Home-features-section-pieces {
    padding-top: 40px;
  }
}

.Home-features-section-pieces h2 {
  color: #fff;
  font-weight: 600;
  font-size: 18px;
}

.Home-features-section-pieces p {
  color: #dfeeff;
}

.Home-timer-numbers {
  font-size: 60px;
  font-weight: 500;
  font-family: "Oxanium";
  color: #f55472;
}

.Home-features-board-title {
  font-size: 42px;
  font-weight: 500;
  font-family: "Poppins";
  color: #577ea8;
  margin-left: -4px;
}

.Home-features-image {
  height: 160px;
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 960px) {
  .Home-features-image {
    height: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.blinking-cursor {
  font-weight: 100;
  font-size: 50px;
  color: #7a8da1;
  animation: 1s blink step-end infinite;
}

@keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #7a8da1;
  }
}

.Footer {
  min-height: 200px;
  width: 100%;
  background-color: #022855;
}

.Footer h2 {
  color: #dfeeff;
}

.Footer p {
  color: #dfeeff;
}

.Footer-content {
  width: 80%;
  max-width: 1400px;
  margin: auto;
  color: #fff;
  justify-content: space-around;
  padding-top: 30px;
  padding-bottom: 10px;
}

@media (max-width: 960px) {
  .Footer-content {
    width: 90%;
  }
}

.Footer-content-sections {
  padding-bottom: 20px;
  padding-left: 5%;
  padding-right: 5%;
  /* padding-bottom: 20px; */
}
.Footer-content-header {
  font-size: 24px;
  font-weight: 700;
}

.Wave-container {
  /* background: linear-gradient(#dfeeff, transparent); */
  height: 5%;
  width: 100%;
  /* overflow: hidden; */
  position: absolute;
  top: 0;
  left: 0;
  /* z-index: -1; */
  /* transform: rotate(180deg); */
  /* background: #015871; */
}

.Wave {
  background: url(/static/media/topWave.d8bfba24.svg) repeat-x;
  position: absolute;
  /* top: -198px; */
  top: 23;
  width: 6400px;
  height: 198px;
  animation: wave 15s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
}

.Wave:nth-of-type(2) {
  /* top: -175px; */
  animation: wave 15s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
    swell 10s ease -1.25s infinite;
  opacity: 1;
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}

@keyframes swell {
  0%,
  100% {
    transform: translate3d(0, -25px, 0);
  }
  50% {
    transform: translate3d(0, 5px, 0);
  }
}

@keyframes swell2 {
  0%,
  100% {
    transform: translate3d(0, -15px, 0);
  }
  50% {
    transform: translate3d(0, 5px, 0);
  }
}

h2 {
  color: #555;
  font-size: 24px;
  font-weight: 500;
}

p {
  /* font-size: calc(10px + 1vmin); */
}

a {
  color: #ffc5b3;
  text-decoration: none;
}
a:hover {
  color: #eea28b;
}
/* a {
    color: #4083D2;
    text-decoration: none;
  }
  a:hover {
     color: #2469BB;
  } */

.Admin {
  background-color: #eff7ff;
  min-height: 100vh;
}

.Admin-left-bar {
  /* display: flex;
  flex-direction: column;
  justify-content: space-between; */
  background-color: #022855;
  color: #fafafa;
  min-height: 100vh;
}

@media (max-width: 800px) {
  .Admin-left-bar {
    height: auto;
    min-height: 0;
  }
}

.Admin-left-bar h3 {
  color: #fafafa;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  margin: 0;
  margin-bottom: 6px;
}

.Admin-logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Admin-left-bar-logo {
  display: flex;
  /* width: 150px; */
  margin-top: 40px;
}

.Admin-body {
  /* margin-left: 5%;
    margin-right: 20% */
}

.Admin-top-half {
  background-color: #022855;
}

@media (max-width: 800px) {
  .Admin-top-half {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.Admin-logo {
  margin-left: 60px;
  margin-top: 40px;
}

@media (max-width: 800px) {
  .Admin-logo {
    width: 150px;
    margin-top: 30px;
    margin-left: 0;
    margin-right: 0;
  }
}

.Admin-header {
  padding-top: 20px;
  /* width: 740px; */
  width: 80%;
  margin: auto;
  margin-left: 5%;
  margin-right: 20%;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: #444;
}

@media (max-width: 1100px) {
  .Admin-header {
    width: 86%;
    margin-left: 5%;
    margin-right: 5%;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.Admin-title {
  font-size: calc(10px + 5vmin);
  font-family: "Poppins", sans-serif;
  resize: none;
  /* overflow: hidden; */
  border: 0;
  padding-top: 0px;
  outline: none;
  color: #eee;
  color: #333;
  /* background-color: #022855; */
  background-color: #eff7ff;
  font-weight: 600;
  padding-bottom: 20px;
}
::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ccc;
  opacity: 1; /* Firefox */
}
:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ccc;
  opacity: 1; /* Firefox */
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ccc;
  opacity: 1; /* Firefox */
}

.Admin-prompt {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  /* height: 5rem; */
  resize: none;
  border: 0;
  outline: none;
  /* overflow: hidden; */
  /* color: #eee; */
  /* background-color: #022855; */
  background-color: #eff7ff;
  color: #333;
  padding-bottom: 10px;
}
::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #9dabbc;
  opacity: 1; /* Firefox */
}
:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #9dabbc;
  opacity: 1; /* Firefox */
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #9dabbc;
  opacity: 1; /* Firefox */
}

@media (max-width: 800px) {
  .Admin-prompt {
    font-size: calc(12px + 1vmin);
    height: auto;
  }
}

.Admin-instructions {
  /* background-color: #24548C; */
  color: #eee;
  font-size: 16px;
  width: 85%;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  word-wrap: break-word;
}
p {
  line-height: 24px;
}
h2 {
  color: #eee;
}

.preloader {
  width: 20%;
  margin-top: 20%;
  margin-left: 30%;
  margin-right: 50%;
}

.Admin-timer {
  /* margin-bottom: 20px; */
  /* margin-top: -10px; */
  align-self: center;
  font-family: "Oxanium";
  font-size: 16px;
  /* font-weight: 300; */
}

.Admin-timer-numbers {
  margin-bottom: 0;
  font-size: 36px;
  font-weight: 500;
  font-family: "Oxanium";
  color: #d6425d;
}

.Admin-set-timer {
}

.Admin-set-timer-expand {
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 30px;
  /* padding-left: 0; */
  color: #fff;
}

@media (max-width: 800px) {
  .preloader {
    margin-left: 40%;
    margin-right: 40%;
  }
}

.Admin-left-bar-bottom {
  width: 85%;
  margin: auto;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: center;
}

@media (max-width: 600px) {
  .Admin-left-bar-bottom {
    display: none;
  }
}

.Admin-mobile-bottom {
  display: none;
}

@media (max-width: 600px) {
  .Admin-mobile-bottom {
    display: block;
    text-align: center;
    width: 85%;
    margin: auto;
    display: flex;
    flex-direction: column;
    /* margin-top: 20px; */
    margin-bottom: 20px;
    justify-content: center;
  }
}

.Admin-bottom-half {
  /* align-items: center; */
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  background-color: #eff7ff;
  margin-left: 5%;
  margin-right: 20%;
}

@media (max-width: 1100px) {
  .Admin-bottom-half {
    margin-left: 5%;
    margin-right: 5%;
  }
}

.Admin-responses {
  /* width: 740px; */
  /* margin: auto; */
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 1vmin);
  border: 0;
  outline: none;
  color: #222;
  padding-bottom: 20px;
}

.Admin-responses-header {
  color: #24548c;
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.Admin-responses-input {
  display: flex;
  flex-direction: row;
  align-items: self-start;
  margin-bottom: 20px;
}

@media (max-width: 800px) {
  .Admin-responses-input {
    flex-direction: column;
  }
}

@media (max-width: 800px) {
  .Admin-responses-input-submit {
    margin-top: 20px;
    /* align-self: center; */
  }
}

.Admin-response-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.Admin-responses-delete {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 8px;
  margin-left: 10px;
  height: 20px;
  color: #888;
  cursor: pointer;
}

.Admin-responses-dropdown {
  width: 140px;
  /* background-color: #F1F8FF; */
}

.Admin-responses-delete-icon {
  padding-left: 10px;
  color: #718baa;
  /* height: calc(10px + 1vmin); */
}

.Admin-responses-category-modal {
  background-color: #fff;
  width: 800px;
  margin: auto;
  /* margin-top: 10%; */
  /* overflow: scroll; */
  border-radius: 20px;
  outline: none;
}

@media (max-width: 800px) {
  .Admin-responses-category-modal {
    width: 100%;
    border-radius: 0px;
    margin-top: 0;
  }
}

.Admin-responses-category-container {
  margin: 20px;
  padding: 20px;
}

.Admin-responses-category-modal-entry {
  display: flex;
}

.Admin-responses-category-modal-button {
  display: flex;
  justify-content: flex-end;
}

.Admin-responses-category-modal h2 {
  color: #24548c;
  font-size: 30px;
  font-weight: 600;
}

.Admin-responses-category-modal h3 {
  color: #333;
  font-weight: 600;
}

.Admin-response-date {
  font-size: calc(8px + 1vmin);
  outline: none;
  color: #666;
  margin-top: 10px;
  margin-bottom: 15px;
}

.Admin-link {
  color: #61dafb;
}

.Category-modal {
}

.Scrollable {
  max-height: 450px;
  overflow: scroll;
}

.Category-modal-category {
  background-color: rgb(255, 145, 0);
}

.Category-modal-delete {
  cursor: pointer;
  color: #666;
  margin-right: 10px;
}

.Delete-dialog h2 {
  color: #555;
}

.Top-container {
  height: 100vh;
  background: radial-gradient(
    ellipse at center,
    rgb(255, 252, 247) 0%,
    rgb(255, 252, 245) 35%,
    #dfeeff 100%
  );
  /* overflow: hidden; */
}

.Four-logo {
  margin-left: 60px;
  margin-top: 30px;
}

@media (max-width: 800px) {
  .Four-logo {
    display: flex;
    justify-content: center;
    margin: auto;
    padding-top: 30px;
  }
}

.FourText {
  text-align: center;
}

.FourText h1 {
  color: #d6425d;
  margin-top: 60px;
  margin-bottom: 10px;
}

.FourText p {
  color: #525e6b;
  font-size: 18px;
}

.Wave-bottom-container {
  /* position: absolute; */
  display: flex;
  flex-direction: column;
  /* justify-content: flex-end; */
  bottom: 0;
  height: 100%;
  /* height: 30%; */
  /* display: flex; */
  /* align-items: flex-end; */
}

.FourO {
  /* position: relative; */
  display: flex;
  width: 100vw;
  justify-content: center;
  padding-top: 50px;
  margin-bottom: -150px;
  font-size: 170px;
  font-family: "Poppins", sans-serif;
  color: #d6425d;
  font-weight: 700;
  animation: swellRock 10s ease-out -2.25s infinite;
  transform: translate3d(0, 0px, 0);
}

@media (max-width: 1600px) {
  .FourO {
    animation: swellSmall 10s ease-out -2.25s infinite;
    transform: translate3d(0, 0, 0);
  }
}

@media (max-width: 1000px) {
  .FourO {
    animation: swellXS 10s ease-out -2.25s infinite;
    transform: translate3d(0, 30px, 0);
  }
}

@media (max-width: 450px) {
  .FourO {
    animation: swellXXS 10s ease-out -2.25s infinite;
    transform: translate3d(0, 0, 0);
  }
}

@media (min-width: 2000px) {
  .FourO {
    animation: swellLarge 10s ease-out -2.25s infinite;
    transform: translate3d(0, -30px, 0);
  }
}

@media (min-width: 2800px) {
  .FourO {
    animation: swellXL 10s ease-out -2.25s infinite;
    transform: translate3d(0, 0, 0);
  }
}

.FourORotate {
  animation: rock 10s linear infinite;
  transform: rotate(0);
}

@media (max-width: 1000px) {
  .FourORotate {
    animation: rockXS 10s ease-out infinite;
    transform: rotate(-6deg);
  }
}

@media (max-width: 450px) {
  .FourORotate {
    animation: rockXXS 10s ease-out infinite;
    transform: rotate(0deg);
  }
}

@media (min-width: 2000px) {
  .FourORotate {
    animation: rockLarge 10s ease-out infinite;
    transform: rotate(6deg);
  }
}

@media (min-width: 2800px) {
  .FourORotate {
    animation: rockXL 10s linear infinite;
    transform: rotate(0deg);
  }
}

.Wave-bottom {
  background: url(/static/media/bottomWave.c770c2ee.svg) repeat-x;
  position: absolute;
  /* top: -198px; */
  margin-top: 50px;
  width: 6400px;
  height: 198px;
  animation: wave 10s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
}

.Wave-bottom:nth-of-type(2) {
  /* top: -175px; */
  animation: wave 10s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
    swell 15s ease -1.25s infinite;
  opacity: 1;
}

.Deep-sea {
  z-index: 1;
  position: relative;
  /* bottom: 0; */
  margin-top: 220px;
  height: calc(100vh - 550px);
  /* height: 100px; */
  width: 100%;
  background: linear-gradient(#054188, #011a38);
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}

@keyframes swell {
  0%,
  100% {
    transform: translate3d(0, -30px, 0);
  }
  50% {
    transform: translate3d(0, 5px, 0);
  }
}

@keyframes swellXXS {
  25% {
    transform: translate3d(0, 30px, 0);
    animation-timing-function: ease-in;
  }
  50% {
    transform: translate3d(0, 0, 0);
    animation-timing-function: ease-out;
  }
  75% {
    transform: translate3d(0, -30px, 0);
    animation-timing-function: ease-in;
  }
  100% {
    transform: translate3d(0, 0, 0);
    animation-timing-function: ease-out;
  }
}

@keyframes swellXS {
  0% {
    transform: translate3d(0, 30px, 0);
    animation-timing-function: ease-in;
  }
  25% {
    transform: translate3d(0, 0, 0);
    animation-timing-function: ease-out;
  }
  50% {
    transform: translate3d(0, -30px, 0);
    animation-timing-function: ease-in;
  }
  75% {
    transform: translate3d(0, 0, 0);
    animation-timing-function: ease-out;
  }
}

@keyframes swellSmall {
  25% {
    transform: translate3d(0, -30px, 0);
    animation-timing-function: ease-in;
  }
  50% {
    transform: translate3d(0, 0, 0);
    animation-timing-function: ease-out;
  }
  75% {
    transform: translate3d(0, 30px, 0);
    animation-timing-function: ease-in;
  }
  100% {
    transform: translate3d(0, 0, 0);
    animation-timing-function: ease-out;
  }
}

@keyframes swellRock {
  25% {
    transform: translate3d(0, -30px, 0);
    animation-timing-function: ease-in;
  }
  50% {
    transform: translate3d(0, 0, 0);
    animation-timing-function: ease-out;
  }
  75% {
    transform: translate3d(0, 30px, 0);
    animation-timing-function: ease-in;
  }
  100% {
    transform: translate3d(0, 0, 0);
    animation-timing-function: ease-out;
  }
}

@keyframes swellLarge {
  0% {
    transform: translate3d(0, -30px, 0);
    animation-timing-function: ease-in;
  }
  25% {
    transform: translate3d(0, 0, 0);
    animation-timing-function: ease-out;
  }
  50% {
    transform: translate3d(0, 30px, 0);
    animation-timing-function: ease-in;
  }
  75% {
    transform: translate3d(0, 0, 0);
    animation-timing-function: ease-out;
  }
}

@keyframes swellXL {
  25% {
    transform: translate3d(0, 30px, 0);
    animation-timing-function: ease-in;
  }
  50% {
    transform: translate3d(0, 0, 0);
    animation-timing-function: ease-out;
  }
  75% {
    transform: translate3d(0, -30px, 0);
    animation-timing-function: ease-in;
  }
  100% {
    transform: translate3d(0, 0, 0);
    animation-timing-function: ease-out;
  }
}

@keyframes rock {
  25% {
    transform: rotate(6deg);
    animation-timing-function: ease-in;
  }
  50% {
    transform: rotate(0deg);
    animation-timing-function: ease-out;
  }
  75% {
    transform: rotate(-6deg);
    animation-timing-function: ease-in;
  }
  100% {
    transform: rotate(0deg);
    animation-timing-function: linear;
  }
}

@keyframes rockXS {
  0% {
    transform: rotate(-6deg);
    animation-timing-function: ease-in;
  }
  25% {
    transform: rotate(0deg);
    animation-timing-function: ease-out;
  }
  50% {
    transform: rotate(6deg);
    animation-timing-function: ease-in;
  }
  75% {
    transform: rotate(0deg);
    animation-timing-function: linear;
  }
}

@keyframes rockXXS {
  25% {
    transform: rotate(-6deg);
    animation-timing-function: ease-in;
  }
  50% {
    transform: rotate(0deg);
    animation-timing-function: ease-out;
  }
  75% {
    transform: rotate(6deg);
    animation-timing-function: ease-in;
  }
  100% {
    transform: rotate(0deg);
    animation-timing-function: linear;
  }
}

@keyframes rockLarge {
  0% {
    transform: rotate(6deg);
    animation-timing-function: ease-in;
  }
  25% {
    transform: rotate(0deg);
    animation-timing-function: ease-out;
  }
  50% {
    transform: rotate(-6deg);
    animation-timing-function: ease-in;
  }
  75% {
    transform: rotate(0deg);
    animation-timing-function: linear;
  }
}

@keyframes rockXL {
  25% {
    transform: rotate(-6deg);
    animation-timing-function: ease-in;
  }
  50% {
    transform: rotate(0deg);
    animation-timing-function: ease-out;
  }
  75% {
    transform: rotate(6deg);
    animation-timing-function: ease-in;
  }
  100% {
    transform: rotate(0deg);
    animation-timing-function: linear;
  }
}

