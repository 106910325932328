h2 {
  color: #555;
  font-size: 24px;
  font-weight: 500;
}

p {
  /* font-size: calc(10px + 1vmin); */
}

a {
  color: #ffc5b3;
  text-decoration: none;
}
a:hover {
  color: #eea28b;
}
/* a {
    color: #4083D2;
    text-decoration: none;
  }
  a:hover {
     color: #2469BB;
  } */

.Admin {
  background-color: #eff7ff;
  min-height: 100vh;
}

.Admin-left-bar {
  /* display: flex;
  flex-direction: column;
  justify-content: space-between; */
  background-color: #022855;
  color: #fafafa;
  min-height: 100vh;
}

@media (max-width: 800px) {
  .Admin-left-bar {
    height: auto;
    min-height: 0;
  }
}

.Admin-left-bar h3 {
  color: #fafafa;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  margin: 0;
  margin-bottom: 6px;
}

.Admin-logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Admin-left-bar-logo {
  display: flex;
  /* width: 150px; */
  margin-top: 40px;
}

.Admin-body {
  /* margin-left: 5%;
    margin-right: 20% */
}

.Admin-top-half {
  background-color: #022855;
}

@media (max-width: 800px) {
  .Admin-top-half {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.Admin-logo {
  margin-left: 60px;
  margin-top: 40px;
}

@media (max-width: 800px) {
  .Admin-logo {
    width: 150px;
    margin-top: 30px;
    margin-left: 0;
    margin-right: 0;
  }
}

.Admin-header {
  padding-top: 20px;
  /* width: 740px; */
  width: 80%;
  margin: auto;
  margin-left: 5%;
  margin-right: 20%;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: #444;
}

@media (max-width: 1100px) {
  .Admin-header {
    width: 86%;
    margin-left: 5%;
    margin-right: 5%;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.Admin-title {
  font-size: calc(10px + 5vmin);
  font-family: "Poppins", sans-serif;
  resize: none;
  /* overflow: hidden; */
  border: 0;
  padding-top: 0px;
  outline: none;
  color: #eee;
  color: #333;
  /* background-color: #022855; */
  background-color: #eff7ff;
  font-weight: 600;
  padding-bottom: 20px;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ccc;
  opacity: 1; /* Firefox */
}

.Admin-prompt {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  /* height: 5rem; */
  resize: none;
  border: 0;
  outline: none;
  /* overflow: hidden; */
  /* color: #eee; */
  /* background-color: #022855; */
  background-color: #eff7ff;
  color: #333;
  padding-bottom: 10px;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #9dabbc;
  opacity: 1; /* Firefox */
}

@media (max-width: 800px) {
  .Admin-prompt {
    font-size: calc(12px + 1vmin);
    height: auto;
  }
}

.Admin-instructions {
  /* background-color: #24548C; */
  color: #eee;
  font-size: 16px;
  width: 85%;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  word-wrap: break-word;
}
p {
  line-height: 24px;
}
h2 {
  color: #eee;
}

.preloader {
  width: 20%;
  margin-top: 20%;
  margin-left: 30%;
  margin-right: 50%;
}

.Admin-timer {
  /* margin-bottom: 20px; */
  /* margin-top: -10px; */
  align-self: center;
  font-family: "Oxanium";
  font-size: 16px;
  /* font-weight: 300; */
}

.Admin-timer-numbers {
  margin-bottom: 0;
  font-size: 36px;
  font-weight: 500;
  font-family: "Oxanium";
  color: #d6425d;
}

.Admin-set-timer {
}

.Admin-set-timer-expand {
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 30px;
  /* padding-left: 0; */
  color: #fff;
}

@media (max-width: 800px) {
  .preloader {
    margin-left: 40%;
    margin-right: 40%;
  }
}

.Admin-left-bar-bottom {
  width: 85%;
  margin: auto;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: center;
}

@media (max-width: 600px) {
  .Admin-left-bar-bottom {
    display: none;
  }
}

.Admin-mobile-bottom {
  display: none;
}

@media (max-width: 600px) {
  .Admin-mobile-bottom {
    display: block;
    text-align: center;
    width: 85%;
    margin: auto;
    display: flex;
    flex-direction: column;
    /* margin-top: 20px; */
    margin-bottom: 20px;
    justify-content: center;
  }
}

.Admin-bottom-half {
  /* align-items: center; */
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  background-color: #eff7ff;
  margin-left: 5%;
  margin-right: 20%;
}

@media (max-width: 1100px) {
  .Admin-bottom-half {
    margin-left: 5%;
    margin-right: 5%;
  }
}

.Admin-responses {
  /* width: 740px; */
  /* margin: auto; */
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 1vmin);
  border: 0;
  outline: none;
  color: #222;
  padding-bottom: 20px;
}

.Admin-responses-header {
  color: #24548c;
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.Admin-responses-input {
  display: flex;
  flex-direction: row;
  align-items: self-start;
  margin-bottom: 20px;
}

@media (max-width: 800px) {
  .Admin-responses-input {
    flex-direction: column;
  }
}

@media (max-width: 800px) {
  .Admin-responses-input-submit {
    margin-top: 20px;
    /* align-self: center; */
  }
}

.Admin-response-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.Admin-responses-delete {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 8px;
  margin-left: 10px;
  height: 20px;
  color: #888;
  cursor: pointer;
}

.Admin-responses-dropdown {
  width: 140px;
  /* background-color: #F1F8FF; */
}

.Admin-responses-delete-icon {
  padding-left: 10px;
  color: #718baa;
  /* height: calc(10px + 1vmin); */
}

.Admin-responses-category-modal {
  background-color: #fff;
  width: 800px;
  margin: auto;
  /* margin-top: 10%; */
  /* overflow: scroll; */
  border-radius: 20px;
  outline: none;
}

@media (max-width: 800px) {
  .Admin-responses-category-modal {
    width: 100%;
    border-radius: 0px;
    margin-top: 0;
  }
}

.Admin-responses-category-container {
  margin: 20px;
  padding: 20px;
}

.Admin-responses-category-modal-entry {
  display: flex;
}

.Admin-responses-category-modal-button {
  display: flex;
  justify-content: flex-end;
}

.Admin-responses-category-modal h2 {
  color: #24548c;
  font-size: 30px;
  font-weight: 600;
}

.Admin-responses-category-modal h3 {
  color: #333;
  font-weight: 600;
}

.Admin-response-date {
  font-size: calc(8px + 1vmin);
  outline: none;
  color: #666;
  margin-top: 10px;
  margin-bottom: 15px;
}

.Admin-link {
  color: #61dafb;
}
